.buysellmodal {
    margin-right: 20px;
    margin-left: 20px;
}

.closebuysell {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.buysellheader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    background-color: #14161b;
    border-radius: 10px;
    flex-direction: column;
}

.headerbidask {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.bidaskconatiner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    padding-top: 5px;
}

.buysellbid {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 7px;
}

.buysellbidask {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 7px;
}

.buybidasktext {
    font-size: 12px;
    padding-bottom: 5px;
    color: #ffffff;
}

.qtycontainer {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.minqtycontainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.minqtytext {
    color: #ffffff;
    font-size: 10px;
    padding-bottom: 5px;
    text-transform: uppercase;
    text-align: center;
}

.buyselltype {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 45px;
    margin: 10;

}

.typeitem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.typebutton {
    color: #ffffff;
    font-size: 12px;
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    border-color: #435abd;
}

.lotpricetext {
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    flex: 1;
}

.buysellbuttonmain {
    position: absolute;
    bottom: 0px;
    margin: 10px;
    height: 45px;
    background-color: #000;
    width: 98%;
    align-self: center;
    left: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buysellinput {
    height: 45px;
    width: 140px !important;
    font-size: 14px !important;

}

@media all and (max-width: 1920px) {

    .buysellmodal {
        margin-right: 10px;
        margin-left: 10px;
    }

    .closebuysell {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .buysellheader {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        background-color: #14161b;
        border-radius: 10px;
        flex-direction: column;
        padding-top: 10px;
    }


    .headerbidask {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .bidaskconatiner {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding-top: 5px;
    }

    .buysellbid {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buysellbidask {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buybidasktext {
        font-size: 14px;
        padding-bottom: 5px;
        color: #ffffff;
    }

    .qtycontainer {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .minqtycontainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .minqtytext {
        color: #ffffff;
        font-size: 10px;
        padding-bottom: 5px;
        text-transform: uppercase;
        text-align: center;
    }

    .buyselltype {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        margin: 10px;
    }

    .typeitem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .typebutton {
        color: #ffffff;
        font-size: 12px;
        height: 40px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        border-color: #435abd;
    }

    .lotpricetext {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        flex: 1;
    }

    .buysellbuttonmain {
        position: absolute;
        bottom: 0px;
        margin: 10px;
        height: 45px;
        background-color: #000;
        width: 98%;
        align-self: center;
        left: -7px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buysellinput {
        height: 45px;
        width: 240px !important;
        font-size: 14px !important;

    }

}

@media all and (max-width: 1280px) {


    .buysellmodal {
        margin-right: 10px;
        margin-left: 10px;
    }

    .closebuysell {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .buysellheader {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        background-color: #14161b;
        border-radius: 10px;
        flex-direction: column;
        padding-top: 10px;
    }


    .headerbidask {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .bidaskconatiner {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding-top: 5px;
    }

    .buysellbid {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buysellbidask {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buybidasktext {
        font-size: 14px;
        padding-bottom: 5px;
        color: #ffffff;
    }

    .qtycontainer {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .minqtycontainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .minqtytext {
        color: #ffffff;
        font-size: 10px;
        padding-bottom: 5px;
        text-transform: uppercase;
        text-align: center;
    }

    .buyselltype {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        margin: 10px;
    }

    .typeitem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .typebutton {
        color: #ffffff;
        font-size: 12px;
        height: 40px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        border-color: #435abd;
    }

    .lotpricetext {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        flex: 1;
    }

    .buysellbuttonmain {
        position: absolute;
        bottom: 0px;
        margin: 10px;
        height: 45px;
        background-color: #000;
        width: 98%;
        align-self: center;
        left: -7px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buysellinput {
        height: 45px;
        width: 240px !important;
        font-size: 14px !important;

    }

}

@media all and (max-width: 601px) {

    .buysellmodal {
        margin-right: 10px;
        margin-left: 10px;
    }

    .closebuysell {
        display: flex;
        background-color: #14161b;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        color: #ff3d32;
    }

    .buysellheader {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        background-color: #14161b;
        border-radius: 10px;
        flex-direction: column;
        padding-top: 10px;
    }


    .headerbidask {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .bidaskconatiner {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding-top: 5px;
    }

    .buysellbid {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buysellbidask {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buybidasktext {
        font-size: 12px;
        padding-bottom: 5px;
        color: #ffffff;
    }

    .qtycontainer {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .minqtycontainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .minqtytext {
        color: #ffffff;
        font-size: 8px;
        padding-bottom: 5px;
        text-transform: uppercase;
        text-align: center;
    }

    .buyselltype {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        margin: 10px;
    }

    .typeitem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .typebutton {
        color: #ffffff;
        font-size: 10px;
        height: 35px;
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        border-color: #435abd;
    }

    .lotpricetext {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        flex: 1;
    }

    .buysellbuttonmain {
        position: absolute;
        bottom: 0px;
        margin: 10px;
        height: 45px;
        background-color: #000;
        width: 98%;
        align-self: center;
        left: -7px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buysellinput {
        height: 45px;
        width: 140px !important;
        font-size: 12px !important;

    }

}

@media all and (max-width: 460px) {

    .buysellmodal {
        margin-right: 10px;
        margin-left: 10px;
    }
    .closebuysell {
        display: flex;
        background-color: #14161b;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        color: #ff3d32
    }


    .buysellheader {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 160px;
        background-color: #14161b;
        border-radius: 10px;
        flex-direction: column;
        padding-top: 10px;
    }

    .headerbidask {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .bidaskconatiner {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding-top: 5px;
    }

    .buysellbid {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buysellbidask {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 7px;
    }

    .buybidasktext {
        font-size: 12px;
        padding-bottom: 5px;
        color: #ffffff;
    }

    .qtycontainer {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .minqtycontainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .minqtytext {
        color: #ffffff;
        font-size: 8px;
        padding-bottom: 5px;
        text-transform: uppercase;
        text-align: center;
    }

    .buyselltype {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        margin: 10px;
    }

    .typeitem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .typebutton {
        color: #ffffff;
        font-size: 10px;
        height: 30px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        border-color: #435abd;
    }

    .lotpricetext {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        flex: 1;
    }

    .buysellbuttonmain {
        position: absolute;
        bottom: 0px;
        margin: 10px;
        height: 45px;
        background-color: #000;
        width: 98%;
        align-self: center;
        left: -7px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buysellinput {
        height: 45px;
        width: 140px !important;
        font-size: 14px !important;

    }


}