.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}

.scrolldivu {
  overflow-y: scroll;
  height: 100%;
}

.scrolldivu::-webkit-scrollbar {
  display: none;
}

.scrolldiv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.buysellopenmodal{
  width: 400px;
  z-index: 10px !important;
}

@media all and (max-width: 1920px) {

  .buysellopenmodal{
    width: 500px;
  }

  .watchlistcontainer {
    display: flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    background-color: #000000;
  }

  .watchlistchildcontainer {
    display: flex;
    height: 40px;
    flex: 1;
    justify-content: space-around;
  }

  .watchlistcard {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-color: #435abd;
  }

}

@media all and (max-width: 1280px) {

  .buysellopenmodal{
    width: 500px;
  }

  .watchlistcontainer {
    display: flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    background-color: #000000;
  }

  .watchlistchildcontainer {
    display: flex;
    height: 40px;
    flex: 1;
    justify-content: space-around;
  }

  .watchlistcard {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-color: #435abd;
  }

}

@media all and (max-width: 601px) {

  .buysellopenmodal{
    width: 400px;
  }

  .watchlistcontainer {
    display: flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    background-color: #000000;
  }

  .watchlistchildcontainer {
    display: flex;
    height: 40px;
    flex: 1;
    justify-content:flex-start;
  }

  .watchlistcard {
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-color: #435abd;
  }

  .watchlisttext{
    display: none;
  }

}

@media all and (max-width: 460px) {

  .buysellopenmodal{
    width: 350px;
  }

  .watchlistcontainer {
    display: flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    background-color: #000000;
  }

  .watchlistchildcontainer {
    display: flex;
    height: 40px;
    flex: 1;
    justify-content:flex-start;
  }

  .watchlistcard {
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-color: #435abd;
    font-size: 10px;
    text-transform: uppercase;
  }

  .watchlisttext{
    display: none;
  }

}