.p-dialog .p-dialog-content:last-of-type {
    border-radius: 10px !important;
}

.p-dialog .p-dialog-content {
    background-color: #000000 !important;
    padding: 0 0 0 0;
    border-style: solid;
    border-width: 2px;
    border-color: #435abd;
}

.watchlistcarditem {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #000000;
    position: relative;
    border-style: solid;
    border-width: 1px;
}

@media all and (max-width: 1920px) {
    .watchlistcarditem {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: #000000;
        position: relative;
        border-style: solid;
        border-width: 1px;
    }

    .swip {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }


    .leftcardmonile {
        display: none;
    }

    .bidaskcontainer {
        display: none;
    }

    .websymbolname {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        flex-direction: column;
        text-align: center;

    }

    .webexpiry {
        font-size: 8px;
        padding-top: 5px;
        color: #ff3d32;
    }

    .webbidask {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14;
    }

    .buysellcontainer {
        position: absolute;
        right: 0;
        background-color: #435abd;
        height: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buysellbox {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buysellbutton {
        padding: 10px;
        border-radius: 10px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .deletebutton{
        padding: 10px;
        border-radius: 10px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }


    .deletebuttonmobile{
        display: none;
    }

}

@media all and (max-width: 1280px) {
    .watchlistcarditem {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: #000000;
        position: relative;
        border-style: solid;
        border-width: 1px;
    }

    .swip {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .leftcardmonile {
        display: none;
    }

    .bidaskcontainer {
        display: none;
    }

    .websymbolname {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        flex-direction: column;
        text-align: center;

    }

    .webexpiry {
        font-size: 8px;
        padding-top: 5px;
        color: #ff3d32;
    }

    .webbidask {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14;
    }

    .buysellcontainer {
        position: absolute;
        right: 0;
        background-color: #435abd;
        height: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

    }


    .buysellbutton {
        padding: 10px;
        border-radius: 10px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .deletebutton{
        padding: 10px;
        border-radius: 10px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    } 
    .deletebuttonmobile{
        display: none;
    }

}

@media all and (max-width: 601px) {
    .watchlistcarditem {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        border-radius: 5px;
        background-color: #000000;
        position: relative;
        border-style: solid;
        border-width: 0px;
    }

    .swip {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .leftcardmonile {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        color: #ffffff;
        flex-direction: column;
        text-align: center;
        padding-left: 10px;
        width: 30%;

    }

    .time {

        font-size: 10px;
        padding-bottom: 3px;
    }

    .symbolname {
        display: flex;
    }

    .expiry {
        font-size: 6px;
        padding-left: 5px;
        padding-top: 7px;
        color: #ffffff;
    }


    .livetime {

        font-size: 10px;
        padding-top: 3px;
    }

    .rightcardmobile {
        width: 65%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bidaskcontainer {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        flex-direction: column;
        text-align: center;
        padding-left: 10px;
        font-size: 16px;
    }

    .highlow {
        padding-top: 5px;
        font-size: 10px;
    }

    .websymbolname {
        display: none;
    }

    .webbidask {
        display: none;
    }

    .buysellcontainer {
        display: none;
    }

    .deletebuttonmobile {
        font-size: 22px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

}

@media all and (max-width: 460px) {
    .watchlistcarditem {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        border-radius: 5px;
        background-color: #000000;
        position: relative;
        border-style: solid;
        border-width: 0;
    }

    .swip {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .leftcardmonile {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        color: #ffffff;
        flex-direction: column;
        text-align: center;
        padding-left: 10px;
        width: 30%;
    }

    .time {
        font-size: 10px;
        padding-bottom: 3px;
    }

    .symbolname {
        display: flex;
    }

    .expiry {
        font-size: 6px;
        padding-left: 5px;
        padding-top: 7px;
        color: #ffffff;
    }


    .livetime {
        font-size: 10px;
        padding-top: 3px;
    }


    .rightcardmobile {
        width: 65%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bidaskcontainer {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        flex-direction: column;
        text-align: center;
        padding-left: 10px;
        font-size: 16px;
    }

    .highlow {
        padding-top: 5px;
        font-size: 10px;
    }


    .websymbolname {
        display: none;
    }

    .webbidask {
        display: none;
    }

    .buysellcontainer {
        display: none;
    }

    .deletebuttonmobile {
        font-size: 22px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
}