.p-inputtext:enabled:focus {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.p-inputtext:enabled:focus-visible {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.p-checkbox .p-checkbox-box {
    background: #000000 !important;
}

.containercheckbox {
    width: 250px;
}

.logincheckbox {
    color: #ffffff;
    margin-bottom: 10px;
    width: 80%;
    font-size: 18;
}

.maincontainert {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



@media all and (max-width: 1920px) {

    .logincontainer {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .loginviewDark {
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        background-color: #000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 550px;
        width: 700px;
    }

    .loginimagecontainer {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        height: 200px;
    }

    .sublogo {
        height: 70px;
    }

    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background: #14161b;
        border-width: 0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center !important;
        width: 300px;
        color: #ffffff;
    }

    .loginbutton {
        width: 250px;
        padding: 10px;
        background-color: #435abd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }

    .demologinbutton {
        width: 250px;
        padding: 10px;
        background-color: #ff3d32;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
        font-size: 12px;
    }


}

@media all and (max-width: 1280px) {

    .logincontainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loginviewDark {
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        background-color: #000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 600px;
        width: 500px;
    }

    .loginimagecontainer {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        height: 100px;
    }

    .sublogo {
        height: 30px;
    }

    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background: #14161b;
        border-width: 0;
        margin-bottom: 10px;
        align-self: center;
        text-align: center;
        width: 300px;
        color: #ffffff;
    }

    .loginbutton {
        width: 250px;
        padding: 10px;
        background-color: #435abd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        border-radius: 10px;
        color: #ffffff;
    }

    .demologinbutton {
        width: 250px;
        padding: 10px;
        background-color: #ff3d32;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
        font-size: 12px;
    }

}

@media all and (max-width: 601px) {

    .logincontainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loginviewDark {
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        background-color: #000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 600px;
        width: 400px;
    }

    .loginimagecontainer {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        height: 100px;
    }

    .sublogo {
        height: 40px;
    }

    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background: #14161b;
        border-width: 0;
        margin-bottom: 10px;
        align-self: center;
        text-align: center;
        width: 300px;
        color: #ffffff;
    }

    .loginbutton {
        width: 250px;
        padding: 10px;
        background-color: #435abd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        border-radius: 10px;
        color: #ffffff;
    }

    .demologinbutton {
        width: 250px;
        padding: 10px;
        background-color: #ff3d32;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
        font-size: 12px;
    }

}

@media all and (max-width: 460px) {

    .logincontainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loginviewDark {
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        background-color: #000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 650px;
        width: 350px;
    }

    .loginimagecontainer {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        height: 100px;
    }

    .sublogo {
        height: 30px;
    }


    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background: #14161b;
        border-width: 0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center;
        width: 300px;
        color: #ffffff;
    }

    .loginbutton {
        width: 250px;
        padding: 10px;
        background-color: #435abd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }

    .demologinbutton {
        width: 250px;
        padding: 10px;
        background-color: #ff3d32;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
        font-size: 12px;
    }

}