.container {
   margin-right: 20px;
   margin-left: 20px;
}

.rulestitile {
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #ffffff;
   font-size: 22px;
}

.rulescard {
   background-color: #14161b;
   margin: 10px;
   border-radius: 10px;
   margin-bottom: 20px;
}

.rulestitle{
   padding: 10px;
   color: #ffffff;
   padding-bottom: 5px;
}
.rulesmsg{
   padding: 10px;
   color: #ffffff;
   padding-top: 5px;
}

@media all and (max-width: 1920px) {

   .rulestitile {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 22px;
   }

}


@media all and (max-width: 1280px) {

   .rulestitile {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
   }

}

@media all and (max-width: 601px) {

   .rulestitile {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
   }

}

@media all and (max-width: 460px) {

   .rulestitile {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
     
   }

}