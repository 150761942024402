.linkbalance {
  text-decoration: none !important;
}

.linkdark {
  text-decoration: none !important;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  background-color: #000000;
  padding: 10px;
  border-radius: 7px;
  margin-left: 20px;
}

.redline {
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 2px;
  border-bottom-color: #ff3d32;
}

.linkdark:hover {
  background-color: #14161b !important;
}

.linklight {
  text-decoration: none !important;
}

.linklight:hover {
  background-color: #f2f2f2 !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #2139b7 !important;
}

/* .p-inputswitch .p-inputswitch-slider {
  background: #14161b !important;
} */

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0px;
  outline-offset: 0px;
  box-shadow: none;
}

/* Dropdown Button */
.dropbtn {
  background-color: #000000;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  padding: 10px;
  z-index: 10;
  width: 150px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
  margin-left: 20px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #14161b;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 10px;
}

/* Links inside the dropdown */
.dropdown-content .link {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 10;
  border-radius: 10px;
  margin: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-content .link:hover {
  background-color: #2139b7 !important;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #2139b7;
}



/* Dropdown Button */
.dropbtnprofile {
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 7px;
  z-index: 10;
  width: 70px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* The container <div> - needed to position the dropdown content */
.dropdownprofile {
  position: relative;
  display: inline-block;
  z-index: 10;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-contentprofile {
  display: none;
  position: absolute;
  background-color: #14161b;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 10px;
  right: 20px;
}

.dropdown-contentprofile .link {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 10;
  border-radius: 10px;
  margin: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-contentprofile .link:hover {
  background-color: #2139b7 !important;
}

/* Show the dropdown menu on hover */
.dropdownprofile:hover .dropdown-contentprofile {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdownprofile:hover .dropbtnprofile {
  background-color: #2139b7;
}

.headerlogout {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerlogouticon {
  font-size: 30px;
}



.headeruserlist {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}




@media all and (max-width: 1920px) {



  /* Dropdown Button */
  .dropbtnprofile {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 7px;
    z-index: 10;
    width: 100px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerlogout {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerlogouticon {
    font-size: 30px;
  }

  .topcontainer {
    height: 80px;
    background-color: #000000;
    display: flex;
  }

  .topimagecontainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .linkcontainer {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .linkdark {
    text-decoration: none !important;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    background-color: #000000;
    padding: 10px;
    border-radius: 7px;
    margin-left: 20px;
  }

  .redline {
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 2px;
    border-bottom-color: #ff3d32;
  }


  .profilecontainer {
    flex: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profilebox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerlogout {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }

  .headeruserlist {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .containerwallet {
    background-color: #000000;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  .walletbutton {
    color: #ffffff;
    background-color: #2139b7;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .walleticon {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .icon {
    color: #ffffff;
    font-size: 20px;
    margin-right: 5px;
  }

  .wallettext {
    padding-top: 2px;
    font-size: 14px;
  }

  .balancetext {
    font-size: 14px;
  }


  .profilecontainer {
    flex: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profilebox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media all and (max-width: 1280px) {


  /* Dropdown Button */
  .dropbtnprofile {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 7px;
    z-index: 10;
    width: 60px;
    font-size: 30px;
  }

  .headerlogout {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
  }


  .topcontainer {
    height: 80px;
    background-color: #000000;
  }

  .topimagecontainer {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .linkcontainer {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .linkdark {
    text-decoration: none !important;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    background-color: #000000;
    padding: 10px;
    border-radius: 7px;
    margin-left: 20px;
  }

  .redline {
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 2px;
    border-bottom-color: #ff3d32;
  }


  .profilecontainer {
    flex: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profilebox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerlogout {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }

  .headeruserlist {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media all and (max-width: 601px) {


  /* Dropdown Button */
  .dropbtnprofile {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 7px;
    z-index: 10;
    width: 40px;
    font-size: 22px;
  }

  .topcontainer {
    height: 50px;
    background-color: #000000;
    display: flex;
  }

  .topimagecontainer {
    flex: 1;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .linkcontainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .linkdark {
    text-decoration: none !important;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #ffffff;
    background-color: #14161b;
    padding: 10px;
    border-radius: 7px;
    margin-left: 5px;
  }

  .redline {
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 0px;
    border-bottom-color: #ff3d32;
  }


  .containerwallet {
    background-color: #000000;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  .walletbutton {
    color: #ffffff;
    background-color: #2139b7;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .walleticon {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .icon {
    color: #ffffff;
    font-size: 12px;
    margin-right: 5px;
  }

  .wallettext {
    padding-top: 2px;
    font-size: 10px;
  }

  .balancetext {
    font-size: 10px;
  }

  .profilecontainer {
    flex: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profilebox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerlogout {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }

  .headeruserlist {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media all and (max-width: 460px) {

  .topcontainer {
    height: 50px;
    background-color: #000000;
    display: flex;
  }

  .topimagecontainer {
    flex: 1;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .linkcontainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .linkdark {
    text-decoration: none !important;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #ffffff;
    background-color: #14161b;
    padding: 10px;
    border-radius: 5px;
    margin-left: 5px;
  }

  .redline {
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 0px;
    border-bottom-color: #ff3d32;
  }

  .containerwallet {
    background-color: #000000;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  .walletbutton {
    color: #ffffff;
    background-color: #2139b7;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .walleticon {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .icon {
    color: #ffffff;
    font-size: 12px;
    margin-right: 5px;
  }

  .wallettext {
    padding-top: 2px;
    font-size: 10px;
  }

  .balancetext {
    font-size: 10px;
  }


  .profilecontainer {
    flex: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profilebox {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .headerlogout {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }

  .headeruserlist {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}