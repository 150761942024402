.marquee {
    width: 400px;
}

.containermarquee {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    background-color: #000000;
    padding-left: 30px;
    padding-right: 30px;
}


.marqueetext {
    font-size: 22px;
    color: #ffffff;
    z-index: 0 !important;
}

@media all and (max-width: 1920px) {

    .marquee {
        width: 600px;
    }

    .containermarquee {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        background-color: #000000;
        padding-left: 30px;
        padding-right: 30px;
    }

    .marqueetext {
        font-size: 22px;
        color: #ffffff;
        z-index: 0 !important;
    }

}

@media all and (max-width: 1280px) {

    .marquee {
        width: 500px;
    }

    .containermarquee {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        background-color: #000000;
        padding-left: 30px;
        padding-right: 30px;
    }

    .marqueetext {
        font-size: 22px;
        color: #ffffff;
        z-index: 0 !important;
    }

}

@media all and (max-width: 601px) {

    .marquee {
        width: 400px;
    }

    .containermarquee {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        background-color: #000000;
        padding-left: 30px;
        padding-right: 30px;
    }

    .marqueetext {
        font-size: 22px;
        color: #ffffff;
        z-index: 0 !important;
    }

}

@media all and (max-width: 460px) {

    .marquee {
        width: 350px;
    }

    .containermarquee {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        background-color: #000000;
        padding-left: 30px;
        padding-right: 30px;
    }

    .marqueetext {
        font-size: 18px;
        color: #ffffff;
        z-index: 0 !important;
    }

}