.mainbanknifty {
    height: 20px;
    padding: 5px;
    background-color: #000000;
}

@media all and (max-width: 1920px) {
    .mainbanknifty {
        height: 20px;
        padding: 5px;
        background-color: #000000;
    }

    .bankniftycontainer {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .niftybankniftycard {
        display: flex;
        padding-right: 20px;
        font-size: 14px;
        color: #ffffff;
    }

    .niftybankniftycardtext {
        font-size: 14px;
        margin-left: 5px;
    }
}

@media all and (max-width: 1280px) {
    .mainbanknifty {
        height: 20px;
        padding: 5px;
        background-color: #000000;
    }

    .bankniftycontainer {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .niftybankniftycard {
        display: flex;
        padding-right: 20px;
        font-size: 14px;
        color: #ffffff;
    }

    .niftybankniftycardtext {
        font-size: 14px;
        margin-left: 5px;
    }
}

@media all and (max-width: 601px) {
    .mainbanknifty {
        height: 55px;
        padding: 5px;
        background-color: #000000;
    }

    .bankniftycontainer {
        color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
    }

    .niftybankniftycard {
        display: flex;
        padding-right: 20px;
        font-size: 12px;
        color: #ffffff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .niftybankniftycardtext {
        font-size: 10px;
        margin-left: 5px;
    }
}

@media all and (max-width: 460px) {
    .mainbanknifty {
        height: 50px;
        padding: 5px;
        background-color: #000000;
    }

    .bankniftycontainer {
        color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
    }

    .niftybankniftycard {
        display: flex;
        padding-right: 20px;
        font-size: 10px;
        color: #ffffff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .niftybankniftycardtext {
        font-size: 8px;
        margin-left: 5px;
    }
}