.react-confirm-alert-overlay {
    background-color: transparent !important;
}

.react-confirm-alert-body{
    background-color: #14161b;
    font-size: 12px !important;
}

.react-confirm-alert-body > h1{
    color: #fff;
    font-size: 16px !important;
}

.react-confirm-alert-button-group > button {
    background-color: #435abd;
}

