.containermenu {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    padding-left: 10px;
    padding-right: 10px;
}

.listmenudelete{
    width: 0px;
}

@media all and (max-width: 1920px) {

    .containermenu {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        padding-left: 10px;
        padding-right: 10px;
    }

    .listmenufirst {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .listmenu {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .listmenumobile {
        display: none;
    }

    .listmenudelete{
        width: 0px;
    }


}

@media all and (max-width: 1280px) {

    .containermenu {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        padding-left: 10px;
        padding-right: 10px;
    }

    .listmenufirst {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .listmenu {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .listmenumobile {
        display: none;
    }

    .listmenudelete{
        width: 0px;
    }



}

@media all and (max-width: 601px) {

    .containermenu {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        padding-left: 10px;
        padding-right: 10px;
    }

    .listmenufirst {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .listmenu {
        display: none;
        justify-content: center;
        align-items: center;
        color: #ffffff;

    }

    .listmenumobile {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .listmenudelete{
        width: 20px;
    }


}

@media all and (max-width: 460px) {

    .containermenu {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        padding-left: 10px;
        padding-right: 10px;
    }

    .listmenufirst {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .listmenu {
        flex: 1;
        display: none;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .listmenumobile {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .listmenudelete{
        width: 20px;
    }

}