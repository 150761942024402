
.p-dialog .p-dialog-content {
  border-radius: 10px;

  background-color: #14161b !important;
}

.toastContainer {
  z-index: 9999 !important;
}

.p-dialog-mask.p-component-overlay {
  z-index: 0 !important;
}


